import React, {Fragment, useState} from 'react';
import {
    Button,
    Datagrid,
    DateField,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';

import UserLinkField from "../components/UserLinkField";
import PayoutAmountModal from "./PayoutAmountModal";
import CustomPriceField from '../components/CustomPriceField';

/**
 * Sub-component for filtering a list of settlements.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function SettlementFilter (props) {
    return (
        <Filter {...props}>
            <TextInput source="date" label={'정산예약일'} alwaysOn/>
            <TextInput source="status" alwaysOn label={'상태'}/>
            <TextInput source="seq" alwaysOn/>
        </Filter>
    );
}

/**
 * Component for a list of settlements.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SettlementList({...props}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // build the UI elements
    return (
        <Fragment>
            <List bulkActionButtons={false} filters={<SettlementFilter/>}
                  sort={{field: 'id', order: 'DESC'}}
                  actions={
                      <TopToolbar>
                          <Button
                              label="Nice 정산금액 현황"
                              style={{color: 'green'}}
                              onClick={() => setIsModalOpen(true)}></Button>
                      </TopToolbar>
                  }
                  {...props}>
                <Datagrid rowClick="show">
                    <DateField source="createdAt" label={'생성일'}/>
                    <TextField source="date" label={'정산예약일'}/>
                    <TextField label="SEQ" source="seq"/>
                    <CustomPriceField source="amount"  label={'정산금액'}/>
                    <ReferenceField label="판매자" source="order.seller.user_id" reference="users">
                        <UserLinkField/>
                    </ReferenceField>
                    <TextField source="status" label={'상태'}/>
                    <TextField source="error"/>
                </Datagrid>
            </List>
            {isModalOpen && <PayoutAmountModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />}
        </Fragment>

    );
}
