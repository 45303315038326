import React from 'react';
import {Datagrid, DateField, Filter, List, NumberField, ReferenceField, TextField, TextInput } from 'react-admin';
import CustomPriceField from '../components/CustomPriceField';
/**
 * Sub-component for filtering a list of settlementError.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function SettlementErrorFilter (props) {
    return (
        <Filter {...props}>
            <TextInput source="date" alwaysOn label={'정산예약일'}/>
            <TextInput source="subId" alwaysOn/>
        </Filter>
    );
}

/**
 * Component for a list of settlementError.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SettlementErrorList({...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} filters={<SettlementErrorFilter/>} sort={{field: 'id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="show">
                <DateField source="createdAt" label={'생성일'}/>
                <TextField source="date" label={'정산예약일'}/>
                <TextField source="subId"/>
                <TextField label="SEQ" source="seq"/>
                <CustomPriceField label={'정산금액'} source="amount"/>
                <ReferenceField source="orderId" reference="orders" label={'결제내역'}>
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="status" label={'상태'}/>
                <TextField source="error"/>
            </Datagrid>
        </List>
    );
}
