import React from 'react';
import {DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

/**
 * Component for showing a settlementErrors.
 * @param props the react admin Show component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SettlementErrorShow(props) {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <NumberField source="id"/>
                <ReferenceField source="orderId" reference="orders">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="date"/>
                <TextField label="SEQ" source="seq"/>
                <TextField label="subId" source="subId"/>
                <TextField source="status"/>
                <TextField source="error"/>
                <DateField source="createdAt"/>
                <DateField source="updatedAt"/>
            </SimpleShowLayout>
        </Show>
    );
}
